<template>
  <div>
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="createCategoryHandler"
          icon-pack="feather"
          icon="icon-edit"
          >Add Brand</vs-button>
      </div>
    </div>
    <BrandList
      :data="data"
      :totalDocs="totalDocs"
      :page="page"
      :popUpDeleteParent="popUpDelete"
      :noDataText="noDataText"
      @fetchList="getBrandsList"
      @edit="editBrand"
      @delete="deleteBrand"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BrandList from "../../../components/WebStore/brands/list";

export default {
  components: {
    BrandList
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      popUpDelete: false,
      data: [],
      noDataText: 'Loading...'
    };
  },
  methods: {
    ...mapActions("brand", [
      "getBrandList",
      "deleteBrandById"
    ]),
    ...mapActions("ecommerce",["algoliaSync"]),

    async getBrandsList(params) {
      let self = this;
      this.getBrandList(params).then( async (res) => {
        self.data = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total || 0;
        self.page = res.data.data.pagination.page;
        self.noDataText = 'No Brand Available'
      });
    },
    createCategoryHandler() {
      this.$router.push({ name: 'AddBrand'})
    },
    editBrand(id) {
      this.$router.push({
        name: "EditBrand",
        params: { brandId: id }
      });
    },
    async deleteBrand(data) {
      let self = this;
      await this.deleteBrandById(data.id)
        .then(res => {
          self.algoliaSync()
          self.popUpDelete = false;
          self.getBrandsList(data.dataTableParams);
          this.$vs.notify({
            title: "Success",
            text: "Brand Deleted successfully.",
            color: "success"
          });
        })
        .catch(err => {
          self.popUpDelete = false;
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger"
          });
        });
    }
  }
}
</script>
